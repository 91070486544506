// Angular
import { Component, Input, OnDestroy, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// 3rdparty
import * as Quill from "quill";
import  dayjs from 'dayjs' 
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { firstValueFrom, Subscription } from 'rxjs';

// Hostware
import { SystemMessagesViewerService } from './system-messages-viewer.service';
import { SystemMessageDto } from './SystemMessageDto';

import * as translateFile from "./translate.json";

@AutoUnsubscribe()
@Component({
  selector: 'system-messages-viewer',
  templateUrl: './system-messages-viewer.component.html',
  styleUrls: ['./system-messages-viewer.component.scss']

})
export class SystemMessagesViewerComponent implements OnInit, OnDestroy {

  subSystem: string
  lngCode : string
  loadMessages$: Subscription;
  languageLoaded: boolean = false;
  messages: SystemMessageDto[] = [];
  translation : any;

  title : string;
  buttonText : string;
  parsedLanguageFile =  JSON.parse(JSON.stringify(translateFile));
  constructor(public dialogRef: MatDialogRef<SystemMessagesViewerComponent>, @Inject(MAT_DIALOG_DATA) private data: string,
    private systemMessageService: SystemMessagesViewerService
  ) {

    this.subSystem = data.split(',')[0];
    this.lngCode = data.split(',')[1];

  }


  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit(): void {
   
    this.title = this.parsedLanguageFile["title"][this.lngCode];
    this.buttonText = this.parsedLanguageFile["close"][this.lngCode];
    // this.translation = this.loadTranslation()
    this.loadMessages$ = this.systemMessageService.GetActiveMessages(this.subSystem).subscribe(result => {
      this.messages = result["Result"] as SystemMessageDto[];
      this.messages.forEach(mess=>  {
        mess.StartDate = dayjs(mess.StartDate).format('YYYY.MM.DD HH:mm:ss')
      })
    })

   
  }
  ngOnDestroy(): void {
    this.loadMessages$?.unsubscribe();
  }
  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================
  closeDialog() {
    this.dialogRef.close();
  }
  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  =========================================================================================

  // async loadTranslation() {
  //   const result = await firstValueFrom(this.systemMessageService.loadTranslation())
  //   return result
  // }
  iconClass(item: SystemMessageDto) {
    switch (item.MessageType) {
      case "i":
        return {
          'mdi': true,
          'mdi-information': true,
          'hw-font-size-25': true
        }
      case 'e': {
        return {
          'mdi': true,
          'mdi-exclamation-thick': true,
          'hw-font-size-25': true
        }
      }
    }
  }

  iconStyle(item: SystemMessageDto) {
    let styles = {};
    switch (item.MessageType) {
      case 'i': {
        styles["color"] = "green"
      }
        break;
      case 'e': {
        styles["color"] = "red"
      }
        break;
    }
    return styles
  }

  messageTypeText(item: SystemMessageDto) : string | undefined{
    switch (item.MessageType) {
      case 'i': {
        return (this.parsedLanguageFile["info"][this.lngCode])
      }
      case 'e': {
        return (this.parsedLanguageFile["operation"][this.lngCode])
      }
    }
  }
  //#endregion =================================================================================================================



}
