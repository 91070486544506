// Angular
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatTabGroup } from '@angular/material/tabs';
import { MatRadioChange } from '@angular/material/radio';
// 3rdparty
import { TranslateService } from '@ngx-translate/core';
// Hostware
import { TopbarService } from '@app/services/topbar.service';
import { SubscriberParameterService } from '../../../../../froexishared/src/services/SubscriberParameter.service';
import { Subscriber_ParameterService } from '../../../../../froexishared/src/services/subscriber_parameter.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
import SessionStorage from '@app/helpers/SessionStorage';
import { Subscriber_Parameter } from '../../../../../froexishared/src/dto/Subscriber_Parameter';
import { SubscriberParameter } from '../../../../../froexishared/src/dto/SubscriberParameter';
import { StripePaymentParameters } from '@app/dto/StripePaymentParameters';
import { SimplePaymentParameters } from '@app/dto/SimplePaymentParameters';
import { HwAlertService } from '@globalshared/hw-alert-component/src/lib/hw-alert.service';
import { Alert, ToastPosition } from '@globalshared/hw-alert-component/src/lib/hw-alert.model';
import { PaymentParameters } from '@app/dto/PaymentParameters';
import { CanComponentDeactivate } from '@app/interfaces/CanComponentDeactivate';
import { } from '@app/interfaces/CanComponentDeactivate'
import { HwConfirmationDialogModule } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.module'
import { HwConfirmationDialogComponent, HwConfirmationDialogData } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.component';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service'
import { BigFishPaymentParameters, PayMode } from '@app/dto/BigFishPaymentParameters';


@AutoUnsubscribe()
@Component({
  selector: 'payment-settings',
  templateUrl: './payment-settings.component.html',
  styleUrls: ['./payment-settings.component.scss']
})

@AutoUnsubscribe()
export class PaymentSettingsComponent implements OnInit, OnDestroy {


  stripePaymentParameters: StripePaymentParameters;
  simplePaymentParameters: SimplePaymentParameters;
  bigFishPaymentParameters: BigFishPaymentParameters
  selectedCreditCardType: string;
  editorForm: FormGroup;
  loaded: boolean
  paymentType: string;
  paymentParameters: PaymentParameters = new PaymentParameters();
  paymentParamsTemp : PaymentParameters = new PaymentParameters();

  excludedServices: string;
  excludedServicesParam: SubscriberParameter;
  canExit: boolean = true;
  hotelSelectionChanged$: Subscription;

  update$: Subscription
  constructor(
    private topBarService: TopbarService,
    private translate: TranslateService,
    private subscriberParameterService: SubscriberParameterService,
    private hwAlertService: HwAlertService,
    private fb: FormBuilder
  ) {

    this.editorForm = this.fb.group({
      ExcludedServices: new FormControl("")
    });
    // validatorfunc???

  }

  //#region =============== Angular Lifecyle events ============================================================================
  ngOnInit(): void {
    this.topBarService.SetTitleText(this.translate.instant('common.set_payment'))
    this.loadAll();
    this.hotelSelectionChanged$ = this.topBarService.HotelSelectionChanged.subscribe(result => {
      this.loadAll();
    })
  }
  ngOnDestroy(): void {
    this.hotelSelectionChanged$?.unsubscribe()
    this.update$?.unsubscribe();
  }

  //#endregion =================================================================================================================

  //#region =============== List event hadlers   ===============================================================================
  //#endregion =================================================================================================================

  //#region =============== Form events ========================================================================================

  //#endregion =================================================================================================================

  //#region =============== Dialog events ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Validation    ======================================================================================
  //#endregion =================================================================================================================

  //#region =============== Functions  ================================== =======================================================
  async loadAll() {
    this.loaded = false;
    const paymentTypeResult = await firstValueFrom(this.subscriberParameterService.loadByTypeCode(SessionStorage.SelectedSubscriber, SessionStorage.SelectedHotelId,
      'PAYMENT_TYPE'));
    const paymentTypeRecord = paymentTypeResult as SubscriberParameter;
    this.paymentType = paymentTypeRecord.Param_String_Value

    const paymentParamResult: SubscriberParameter[] = await firstValueFrom(this.subscriberParameterService.loadAll(SessionStorage.SelectedSubscriber, SessionStorage.SelectedHotelId,)) as SubscriberParameter[];
    const paymentParams = paymentParamResult.find(item => item.Param_Typecode == 'PAYMENT_PARAMETERS')
    this.excludedServicesParam = paymentParamResult.find(item => item.Param_Typecode == 'EXCLUDED_SERVICES');
    this.excludedServices = paymentParamResult.find(item => item.Param_Typecode == 'EXCLUDED_SERVICES').Param_String_Value
    this.paymentParameters = JSON.parse(paymentParams.Param_Json_Value) as PaymentParameters

    switch (this.paymentType) {
      case "SIMPLE":
        this.simplePaymentParameters = this.paymentParameters.SIMPLE;
        break;
      case "BIGFISH":
        let bigFishPaymentParametersTemp : BigFishPaymentParameters = new BigFishPaymentParameters()
        this.bigFishPaymentParameters = this.paymentParameters.BIGFISH;
        Object.assign(bigFishPaymentParametersTemp,this.paymentParameters.BIGFISH)
        this.bigFishPaymentParameters.Paymodes = [];
        let bsPaymodesCreditCard : PayMode[]=[];
        let bsPaymodesSzepCard : PayMode[]=[];
        bsPaymodesCreditCard = bigFishPaymentParametersTemp.Paymodes.filter( item=>item.Type=="CREDITCARD")
        bsPaymodesSzepCard =  bigFishPaymentParametersTemp.Paymodes.filter( item=>item.Type=="SZEPCARD")
        bsPaymodesCreditCard.forEach(item=> {
          this.bigFishPaymentParameters.Paymodes.push(item);
        })
        bsPaymodesSzepCard.forEach(item=> {
          this.bigFishPaymentParameters.Paymodes.push(item);
        })
        
        this.editorForm.controls["ExcludedServices"].patchValue(this.excludedServicesParam.Param_String_Value)
        break;
      case "STRIPE":
        this.stripePaymentParameters = this.paymentParameters.STRIPE;
        break;
    }
    this.loaded = true;
  }

  submit() {
    const splittedValue :string[] = this.editorForm.controls["ExcludedServices"].value.split(',')
    let valueToSave: string = ""; 
    splittedValue.forEach(item=> {
      valueToSave+= item.trim() + ","
    })
    valueToSave = valueToSave.substring(0,valueToSave.length-1);

    this.excludedServicesParam.Param_String_Value =valueToSave;
    try {
      this.update$ = this.subscriberParameterService.update(this.excludedServicesParam).subscribe(result => {
        this.hwAlertService.success(this.translate.instant("common.save_ok"), new Alert({ autoClose: true, fade: true, position: ToastPosition.TopRight }));
      })
    } catch (ex) {
      this.hwAlertService.error(this.translate.instant("common.save_error"), new Alert({ autoClose: false, fade: true, position: ToastPosition.TopRight }));

    }

  }

}
//#endregion =================================================================================================================







