export class BigFishPaymentParameters {
  ApiKey: string;
  Paymodes : PayMode[];
  StoreName: string;
  ResponseUrl: string;
  NotificationUrl : string;


}
export class PayMode {
  Name: string;
  Type : string;
  LogoPath: string;
  ShortName: string;
  ResponseUrl: string;
  NotificationUrl: string;

  
}