import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { persistState } from '@datorama/akita';
import { HwAlertModule } from '@globalshared/hw-alert-component/src/lib/hw-alert.module';
import { ChatModule } from '@globalshared/chat/src/lib/chat.module';
import { HwAlertService } from '@globalshared/hw-alert-component/src/lib/hw-alert.service';
import { HwConfirmationDialogModule } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.module';
import { HwConfirmationDialogService } from '@globalshared/hw-confirmation-dialog/src/lib/hw-confirmation-dialog.service';
import { MatDayjsDateModule } from '@globalshared/hw-material-dayjs-adapter/lib/adapter/';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { MaterialModule } from './material.module';
import { LoginComponent } from './pages/login/login.component';

/* dashboard */
import { DocumentIdentificationTileComponent } from './pages/dashboard/document-identification-tile/document-identification-tile.component';
import { InvitationsTileComponent } from './pages/dashboard/invitations-tile/invitations-tile.component';
import { MissingServiceTranslationsComponent } from './pages/dashboard/missing-service-translations/missing-service-translations.component';
import { TranzactionsTileComponent } from './pages/dashboard/tranzactions-tile/tranzactions-tile.component';
import { UsedLanguagesTileComponent } from './pages/dashboard/used-languages-tile/used-languages-tile.component';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isLeapYear from 'dayjs/plugin/isLeapYear';

import 'dayjs/locale/en';
import 'dayjs/locale/hu';

dayjs.extend(customParseFormat)
dayjs.extend(isLeapYear)
const storage = persistState();


import { LOCATION_INITIALIZED } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { environment } from '@environments/environment';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@globalshared/hw-material-dayjs-adapter/lib/adapter/hw-dayjs-date-adapter';
import { provideNgxMask } from 'ngx-mask';
import { HwServicesDialogComponent } from './dialogs/hw-services-dialog/hw-services-dialog.component';
import { AuthGuard } from './guards/auth.guard';
import { HwAuthInterceptorService } from './interceptors/hw-auth-interceptor.service';
import { CheckInDataSettingsComponent } from './pages/check-in-data-settings/check-in-data-settings.component';
import { CustomizeEmailComponent } from './pages/customize-email/customize-email.component';
import { EmailPreviewComponent } from './pages/customize-email/email-preview/email-preview.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DesignPreviewComponent } from './pages/design/design-preview/design-preview.component';
import { DesignComponent } from './pages/design/design.component';
import { HwServicesComponent } from './pages/hw-services/hw-services.component';
import { IdentityVerificationComponent } from './pages/identity-verification/identity-verification.component';
import { PaymentSettingsComponent } from './pages/payment-settings/payment-settings.component';
import { ProfileSettingsComponent } from './pages/profile-settings/profile-settings.component';
import { SettingsComponent } from './pages/settings/settings.component';

import { QuillModule } from 'ngx-quill';
import { FroexisharedModule } from '../../../froexishared/src/lib/froexishared.module';

import { PipesModule } from '@globalshared/pipes/PipesModule';
import { ChatComponent1 } from './pages/chat/chat.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { CurrencyFormatPipe } from './pipes/HuCurrencyFormat.pipe';
import { ChatService } from '@globalshared/chat/src/lib/chat.service';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SystemMessagesViewerModule } from '@globalshared/system-messages-viewer/src/lib/system-messages-viewer.module'
import { SystemMessagesViewerService } from '@globalshared/system-messages-viewer/src/lib/system-messages-viewer.service';
export const HU_FORMATS = {
  parse: {
    dateInput: 'YYYY.MM.DD',
  },
  display: {
    dateInput: 'YYYY.MM.DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
const toolbar = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],

  ['clean'],                                         // remove formatting button

  ['link', 'image', 'video']                         // link and image, video
]


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TopbarComponent,
    SidebarComponent,
    DashboardComponent,
    DesignComponent,
    DesignPreviewComponent,
    CustomizeEmailComponent,
    CheckInDataSettingsComponent,
    EmailPreviewComponent,
    PaymentSettingsComponent,
    ProfileSettingsComponent,
    IdentityVerificationComponent,
    SettingsComponent,
    PageNotFoundComponent,
    HwServicesComponent,
    HwServicesDialogComponent,
    DocumentIdentificationTileComponent,
    InvitationsTileComponent,
    MissingServiceTranslationsComponent,
    TranzactionsTileComponent,
    UsedLanguagesTileComponent,
    CurrencyFormatPipe,
    ChatComponent1,
    
  
  ],
  imports: [
    ChatModule,
    SocketIoModule.forRoot({ url: environment.chatBackendUrl } as SocketIoConfig),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    HwAlertModule,
    ReactiveFormsModule,
    MatDayjsDateModule,
    HwConfirmationDialogModule,
    BrowserAnimationsModule,
    PipesModule,
    FroexisharedModule.forRoot(environment),
    SystemMessagesViewerModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
        ]
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  // entryComponents: [
  //   HwServicesDialogComponent
  // ],


  providers: [
    // { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    HwAlertService,
    AuthGuard,
    HwConfirmationDialogService,
    ChatService,
    SystemMessagesViewerService,{ provide: 'environment', useValue: environment},
    { provide: 'persistStorage', useValue: storage },
    { provide: MAT_DATE_LOCALE, useValue: 'hu-HU' },
    { provide: MAT_DATE_FORMATS, useValue: HU_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: HwAuthInterceptorService, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    // {provide: DateAdapter, useClass: HwDayjsDateAdapter},
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: 'environment', useValue: environment },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    provideNgxMask()

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      let langToSet = 'hu';
      translate.addLangs(['hu', 'en', 'uk']);
      translate.setDefaultLang('hu');
      const browserLang = translate.getBrowserLang();
      if (environment.home) {
        langToSet = 'hu';
      } else {
        langToSet = browserLang.match(/hu|en/) ? browserLang : 'hu';
      }
      translate.setDefaultLang(langToSet);
      translate.use(langToSet).subscribe(() => {
        console.info(`Successfully initialized '${langToSet}' language.'`);
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}

