import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class SystemMessagesViewerService {
  private environment

   constructor(private http: HttpClient, @Inject('environment') environment) {
    this.environment = environment
  }

  GetActiveMessages(subSystem : string) {
    let apiServer = this.environment.apiHost + this.environment.apiPort;
    return this.http.get(apiServer + "/api/systemmessages/getsystemmessages/" + subSystem )
  }
}