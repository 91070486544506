import { AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import SessionStorage from '@app/helpers/SessionStorage';
import { SelectItem } from '@app/interfaces/SelectItem';
import { AuthService } from '@app/services/auth.service';
import { TopbarService } from '@app/services/topbar.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

import { SystemMessagesViewerService} from '@globalshared/system-messages-viewer/src/lib/system-messages-viewer.service'
import { MatDialog } from '@angular/material/dialog';
import { SystemMessageDto } from '@globalshared/system-messages-viewer/src/lib/SystemMessageDto';
import { SystemMessagesViewerComponent } from '@globalshared/system-messages-viewer/src/lib/system-messages-viewer.component';

 getActiveMessages$ : Subscription;
@Component({
  selector: 'topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

@AutoUnsubscribe()
export class TopbarComponent implements OnInit, OnDestroy {
  // @ViewChild("#hotels") hotelList : MatSelect;
  topForm: FormGroup
  hotels: SelectItem[] = [];
  loaded: boolean = false;
  messages : SystemMessageDto[]=[];
  titleText: string = ""
  titletextChanged$: Subscription;
  getActiveMessages$ : Subscription;
  constructor(
    private topBarService: TopbarService,
    private fb: FormBuilder,
    private router : Router,
    private cdref : ChangeDetectorRef,
    private authService : AuthService,
    private  systemMessagesService : SystemMessagesViewerService,
    private messagesDialog : MatDialog
    
    ) {
      this.hotels = SessionStorage.Hotels;
     
    this.topForm = fb.group({
      hotelList: new FormControl(this.hotels[0].value.toString().includes('@99') ? this.hotels[1].value :  this.hotels[0].value)
    })
  }

  ngOnInit() {
    this.authService.loginComplete.subscribe( result=> {
     this.topForm.controls["hotelList"].patchValue(this.hotels[0].value.toString())
     this.cdref.detectChanges();
    })

    this.titletextChanged$ = this.topBarService.TitleTextChanged.subscribe(newText => {
      if(SessionStorage.IsTokenOk) {
        this.titleText = newText
        this.getActiveMessages$=this.systemMessagesService.GetActiveMessages('EXI').subscribe(result=> {
          const res = result as SystemMessageDto[];
          if( res["Result"].length > 0) {
            this.messages = res["Result"];
          }
        })
      }
    })
  }

  ngOnDestroy(): void {
    this.titletextChanged$?.unsubscribe();
    this.getActiveMessages$?.unsubscribe();
  }

  hotelSelectionChanged(event : MatSelectChange) {
    SessionStorage.SelectedHotel = event.value
    this.topBarService.SetHotelSelectionChanged(event.value)
  }

  logout() {
    this.authService.setLoginComplete(false);
    SessionStorage.Clear();
    this.router.navigateByUrl('/login');
    
  }

  rootDisabled( item : SelectItem) : boolean {
    if(item.value.toString().includes('99')) {
      const ur = this.router.url;
      return true
    } else {
      return false;
    }

  }

  openMessages() {
    const dialogRef = this.messagesDialog.open(SystemMessagesViewerComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: "85vw",
      data: "EXI," + "hu"
    })
  
  }

  hasMessage() : boolean {
    return this.messages.length > 0 ? true : false
  }
}
