import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable,Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
}) 

export class SystemMessageService  {   
  private environment
  apiServer: string;

  constructor(private http: HttpClient, @Inject('environment') environment) { 
    this.environment = environment
    this.apiServer = this.environment.apiHost + this.environment.apiPort;
  }

}