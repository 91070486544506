import { ModuleWithProviders, NgModule } from '@angular/core';
import { SystemMessagesViewerComponent } from './system-messages-viewer.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SystemMessageService } from './SystemMessageService';
import { environment } from '../../../../frohok/src/environments/environment';
import { MaterialModule } from './material.module';
import { PipesModule } from "../../../pipes/PipesModule";




@NgModule({
  declarations: [
    SystemMessagesViewerComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    BrowserAnimationsModule,
    PipesModule
],
  exports: [
    SystemMessagesViewerComponent,
    MaterialModule
  ],
  // providers: [
  //   SystemMessageService,{ provide: 'environment', useValue: environment}
  // ]
})
export class SystemMessagesViewerModule {

  static forRoot(environment) : ModuleWithProviders<SystemMessagesViewerModule> {
    return {
      ngModule: SystemMessagesViewerModule,
      providers : [SystemMessageService,{ provide: 'environment', useValue: environment}]
    } 
   
  }

 }
